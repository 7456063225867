import Joi from "joi-browser";

export function getPhaseChangeObject() {
  return [
    [
      {
        type: "select",
        className: "mb-0",
        size: "grow1",
        name: "phaseChange_0_0_phase",
        label: "Phase",
        options: [
          { _id: "0", name: "0", value: "0" },
          { _id: "1", name: "1", value: "1" },
          { _id: "2", name: "2", value: "2" },
          { _id: "3", name: "3", value: "3" },
          { _id: "4", name: "4", value: "4" },
          { _id: "5", name: "5", value: "5" },
        ],
        value: undefined,
        divideRow: true,
        schema: Joi.string().max(30).required(),
      },
      {
        type: "date",
        size: "grow1",
        name: "phaseChange_0_1_TransitionDate",
        label: "Start Date",
        className: "mb-4",
        divideRow: true,
        value: null,
        schema: Joi.any(),
      },
    ],
  ];
}
