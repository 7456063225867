import React from "react";
import { FaEye, FaRegArrowAltCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const AdmissionRecords = (props) => {
  const data = props.data;
  data.sort(
    (a, b) =>
      new Date(b.GuestInDate ? b.GuestInDate : b.GuestInDate) -
      new Date(a.GuestInDate ? a.GuestInDate : a.GuestInDate)
  );
  function dateShower(date) {
    if (date) {
      let dateString = date.slice(0, 10);
      dateString = dateString.split("-");
      return dateString[1] + "-" + dateString[2] + "-" + dateString[0];
    }
  }
  return (
    <div className="fragmentList-Container max-h-64">
      {data.length > 0 ? (
        <div className="fragmentList-List">
          {data.map((admission, i) => (
            <Link
              to={`/dashboard/admission-record/${admission.AdmissionID}`}
              className="nav-item text-black"
            >
              <div
                className={`fragmentList-Item ${
                  !admission?.DateOut && "bg-teal-600 text-white"
                } `}
                key={i.toString()}
              >
                <div className="fragmentList-Item-Title flex-start grow05 max-w-[55px]">
                  {`Stay ${data.length - i}`}
                </div>
                <div className="fragmentList-Item-Title center">
                  {admission.GuestInDate
                    ? dateShower(admission.GuestInDate)
                    : null}
                  {" - "}
                  {admission.DateOut
                    ? dateShower(admission.DateOut)
                    : "Current"}
                </div>

                <FaEye
                  size={18}
                  className={`my-2 text-xl text-teal-700 ${
                    !admission?.DateOut && "bg-teal-600 text-white"
                  } `}
                />
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <div className="fragmentList-NoData">
          No Previous Phases in this Admission
        </div>
      )}
    </div>
  );
};

export default AdmissionRecords;
