/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { updateResidentPhase } from "../../../services/residentService";
import { getPhaseChangeObject } from "../../../utils/phaseChangeObject";

import Form from "../simpleForm";
import { toast } from "react-toastify";

const PhaseChange = ({
  onChange,
  ResId,
  phaseData,
  setPhaseState,
  ...props
}) => {
  const [phaseChangeObject, setPhaseChangeObject] = useState(
    getPhaseChangeObject()
  );
  const [message, setMessage] = useState("");
  const [forceSubmit, setForceSubmit] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const asyncFunc = async () => {
      if (phaseData.length > 0) {
        let tempObject = [...phaseChangeObject];
        tempObject[0][1].value = new Date();
        setPhaseChangeObject(tempObject);
      }
    };
    asyncFunc();
  }, []);

  const handleChange = (name, item) => {
    const tempObject = [...phaseChangeObject];
    tempObject[parseInt(name[1], 10)][parseInt(name[2], 10)] = item;
    setPhaseChangeObject(tempObject);
  };

  const handleSubmit = async () => {
    let selectedPhase = phaseChangeObject[0][0].value;
    let startDate = phaseChangeObject[0][1]?.value;
    if (phaseChangeObject[0][1]?.value?.length < 9) {
      return toast.error("Start Date must be a valid date");
    }
    setShowModal(false);
    if (!startDate) {
      toast.error("Should be a valid date");
      return;
    }
    let tempData = [...phaseData];
    const previousDate = tempData?.reverse()[0]?.inDate?.slice(0, 10);
    console.log(previousDate);
    if (new Date(previousDate) > new Date(startDate)) {
      toast.error("Start Date cannot be prior to previous phase's start date");
      return;
    }
    if (phaseData.some((item) => item?.phase == selectedPhase)) {
      if (!forceSubmit) {
        setShowModal(true);
        return;
      }
    }
    if (String(selectedPhase) && startDate) {
      // Parse the first date into numerical components
      const [fYear, fMonth, fDay] = startDate.split("-").map(Number);

      // Create a Date object for the current date
      const currentDate = new Date();

      // Extract the UTC-based year, month, and day from the current date
      const cYear = currentDate.getUTCFullYear();
      const cMonth = currentDate.getUTCMonth() + 1; // Months are zero-based in JS
      const cDay = currentDate.getUTCDate();

      // Compare the two dates numerically (year, then month, then day)
      let isFirstDateGreater = false;
      if (fYear > cYear) {
        isFirstDateGreater = true;
      } else if (fYear === cYear) {
        if (fMonth > cMonth) {
          isFirstDateGreater = true;
        } else if (fMonth === cMonth) {
          if (fDay > cDay) {
            isFirstDateGreater = true;
          }
        }
      }
      console.log(fDay, cDay);

      if (isFirstDateGreater) {
        toast.error("End Date cannot be a future date");
        return;
      }

      phaseData[phaseData.length - 1].outDate = phaseChangeObject[0][1].value;
      phaseData.push({
        phase: phaseChangeObject[0][0].value,
        inDate: phaseChangeObject[0][1].value,
      });
      const data1 = {
        ResID: ResId,
        phaseData,
      };
      try {
        let { data } = await updateResidentPhase(data1);
        if (data) onChange();
        else setMessage("Failed to Change Phase");
      } catch (error) {
        //@ts-ignore
        setMessage("Failed to Change Phase");
      }
    }
  };

  return (
    <div className="notesCreation-Container">
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded shadow-lg p-6 w-96">
            <h3 className="text-lg font-semibold">Note</h3>
            <p className="mt-2 text-gray-600">
              Resident has already completed this phase, Are you sure you want
              to demote?
            </p>
            <div className="mt-4 flex justify-end">
              <button
                className="px-4 py-2 mr-2 text-white bg-green-500 rounded hover:bg-green-600"
                onClick={() => {
                  setForceSubmit(true);
                  return setTimeout(() => handleSubmit(), 100);
                }}
              >
                Yes
              </button>
              <button
                className="px-4 py-2 text-white bg-gray-500 rounded hover:bg-gray-600"
                onClick={() => setShowModal(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <Form
        buttonLabel={"Change Phase"}
        data={phaseChangeObject}
        onChange={handleChange}
        setPhaseState={setPhaseState}
        cancel={true}
        submit={handleSubmit}
      />
      {message && <div className="updateResident-footer">{message}</div>}
    </div>
  );
};

export default PhaseChange;
