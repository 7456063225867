// @ts-nocheck
import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import {
  getResidentDetails,
  updateResident,
  updateResidentPhase,
  updateResidentImage,
  deleteResident,
  getAllFragments,
  getResidentFragment,
} from "../../services/residentService";
import { getCurrentUser } from "../../services/authService";
import { toast } from "react-toastify";
import {
  financeAccess,
  level1Access,
  level2Access,
  level3Access,
  level4Access,
  level5Access,
} from "../../utils/roles";
import { getResidentUpdateObject } from "../../utils/residentUpdateObject";
import { ListsContext } from "../../contexts/ListsContext";
import Form from "../common/simpleForm";
import NotesList from "../../components/common/residentView_Common_Components/notesList";
import CreateNote from "../../components/common/residentView_Common_Components/createNote";
import FragmentList from "../../components/common/residentView_Common_Components/fragmentsList";
import CreateFragment from "../../components/common/residentView_Common_Components/createFragment";
import UpdateFragment from "../../components/common/residentView_Common_Components/updateFragment";
import PhaseList from "../../components/common/residentView_Common_Components/phaseList";
import PhaseChange from "../../components/common/residentView_Common_Components/phaseChange";
import AdmissionRecords from "../../components/common/residentView_Common_Components/AdmissionRecords";
import AWSImagePickerEdit from "../../components/common/awsImagePickerEdit";
import ResTopBar from "../../components/common/ResTopBar";
import DisciplinePoints from "../../components/common/residentView_Common_Components/DisciplinePoints";
import ServiceAgreementLetters from "./ServiceAgreementLetters";
import Select from "../../components/common/select";
import { Link } from "react-router-dom";
import { getResidentNotes } from "../../services/residentFragments/frag_notes";
import { getList } from "../../services/listService";
import awsService from "../../services/awsService";
import PhaseUpdate from "../../components/common/residentView_Common_Components/phaseUpdate";
function checkDate(date) {
  const inputDate = new Date(date);
  const now = new Date();
  const currentDay = now.getDay();
  const lastSunday = new Date(now);
  lastSunday.setDate(now.getDate() - currentDay);
  lastSunday.setHours(0, 0, 0, 0);
  return inputDate >= lastSunday && inputDate <= now;
}

const UpdateResident = (props) => {
  const ResID = window.location.pathname.split("/")[3];
  const user = getCurrentUser();
  const history = useHistory();

  const [resident, setResident] = useState();
  const [admission, setAdmission] = useState();
  const [notes, setNotes] = useState([]);
  const [admissionHistory, setAdmissionHistory] = useState([]);
  const [fragments, setFragments] = useState([]);
  const [profileUpdateData, setProfileUpdateData] = useState(
    getResidentUpdateObject()
  );
  const [tempprofileUpdateData, setTempProfileUpdateData] = useState([]);
  const [editPhaseIndex, setEditPhaseIndex] = useState(0);
  const [profileEditView, setProfileEditView] = useState(false);
  const [ProfileUpdatemessage, setProfileUpdatemessage] = useState("");
  const [raceList, setRaceList] = useState([]);
  const [counter, setCounter] = useState(0);
  const [noteSubjects, setNoteSubjects] = useState([]);
  const [NotesState, setNotesState] = useState("View");
  const [PhaseState, setPhaseState] = useState("View");
  const [listInfractions, setListInfractions] = useState([]);
  const [FragmentFilter, setFragmentFilter] = useState("all");
  const [FragmentFilterDropdownOptions, setFragmentFilterDropdownOptions] =
    useState([{ _id: "all", name: "All Fragments", value: "all" }]);
  const [showModal, setShowModal] = useState(false);
  const [FragmentToBeUpdated, setFragmentToBeUpdated] = useState({});
  const [phaseInfo, setPhaseInfo] = useState();

  useEffect(() => {
    const fetchResidentDetails = async () => {
      try {
        const { data } = await getResidentDetails(ResID);
        const {
          resident,
          admission,
          notes,
          admissionsHistory,
          noteSubjects,
          raceLists,
        } = data;

        setResident(resident);
        setAdmission(admission);
        localStorage.setItem(
          "resName",
          `${resident.ResFirstName} ${resident.ResLastName}`
        );
        // Update profile data with resident info
        updateProfileData(resident, raceLists);
        setNotes(notes);
        setNoteSubjects(noteSubjects);
        setAdmissionHistory(admissionsHistory);
        getFragments(await getAllFragments(ResID));
      } catch (error) {
        console.error("Error fetching resident details:", error);
      }
    };

    if (ResID) fetchResidentDetails();
  }, [ResID, counter]);

  const updateProfileData = async (residentData, raceLists) => {
    const tempProfileData = [...profileUpdateData];
    tempProfileData.forEach((row) => {
      row.forEach((item) => {
        const key = item.name.split("_")[3];
        if (residentData[key]) item.value = residentData[key];
      });
    });
    // Update list options
    tempProfileData[1][1].options = raceLists;
    tempProfileData[0][0].error = "";
    tempProfileData[0][1].error = "";
    tempProfileData[1][0].error = "";
    tempProfileData[2][0].error = "";
    console.log("tempo", tempProfileData);
    setProfileUpdateData(tempProfileData);
    setTempProfileUpdateData(tempProfileData);
    console.log("updating");
  };

  const getFragments = async (fragments) => {
    console.log(fragments);
    if (fragments) {
      const fragmentsArray = [
        {
          title: "Contacts",
          name: "contacts",
          items: fragments.data?.ResContacts,
          state: "View",
          titleName: "ContactFirstName",
          list: fragments.data?.ContactType,
        },
        {
          title: "Medication",
          name: "medication",
          items: fragments.data?.ResMedicationInfo,
          state: "View",
          titleName: "MedicationName",
        },
        {
          title: "Drug",
          name: "drug",
          items: fragments.data?.ResDrugInfo,
          state: "View",
          titleName: "DrugOfChoice",
        },
        {
          title: "Education",
          name: "education",
          items: fragments.data?.ResEducationInfo,
          state: "View",
          titleName: "EducationLevel",
          list: fragments.data?.EducationLevel,
        },
        {
          title: "Employment",
          name: "employment",
          items: fragments.data?.ResEmployment,
          state: "View",
          titleName: "JobTitle",
        },
        {
          title: "Medical",
          name: "medical",
          items: fragments.data?.ResMedicalInfo,
          state: "View",
          titleName: "Condition",
        },
        {
          title: "Legal",
          name: "legal",
          items: fragments.data?.ResLegalCases,
          state: "View",
          titleName: "CaseName",
        },
        {
          title: "Family",
          name: "family",
          items: fragments.data?.ResFamily,
          state: "View",
          titleName: "ChildName",
        },
        //   { title: "Family", name: "family", items: [], state : "View", titleName : "" },
        //   { title: "Contacts", name: "contacts", items: [], state : "View", titleName : "" },
        //   { title: "Finance", name: "finance", items: [], state : "View", titleName : "" },
      ];
      console.log(fragmentsArray);
      setFragments(fragmentsArray);
    }
  };

  // Memoize formatted admission history
  const formattedAdmissionHistory = useMemo(() => {
    if (!admissionHistory) return [];

    return admissionHistory
      .map((admission) => ({
        _id: admission.AdmissionID,
        out: admission.DateOut,
        in: admission.ProgramInDate || admission.GuestInDate,
      }))
      .sort((a, b) => new Date(b.in) - new Date(a.in));
  }, [admissionHistory]);

  // Handle profile field updates
  const handleProfileFieldUpdation = useCallback((name, item) => {
    setProfileUpdatemessage("");
    setProfileUpdateData((prevData) => {
      const tempProfileData = [...prevData];
      tempProfileData[parseInt(name[1], 10)][parseInt(name[2], 10)] = item;
      return tempProfileData;
    });
  }, []);

  const handleProfileUpdateSubmit = async ({ validation, errorData }) => {
    if (validation) {
      const tempResident = { ResID: resident.ResID };
      profileUpdateData.forEach((row) => {
        row.forEach((item) => {
          const key = item.name.split("_")[3];
          tempResident[key] = item.value;
        });
      });
      console.log(tempResident);
      let additionalData = {
        ResFirstName: resident.ResFirstName,
        ResLastName: resident.ResLastName,
      };
      try {
        let { data } = await updateResident({
          ...tempResident,
          ...additionalData,
        });
        if (data) {
          console.log(data);
          setResident({ ...resident, ...data });
          setProfileUpdatemessage("Saved!");
          setProfileEditView(false);
        } else {
          setProfileUpdatemessage("Failed to Save Resident");
        }
      } catch (error) {
        //@ts-ignore
        setProfileUpdatemessage("Failed to Save Resident");
      }
    } else {
      setProfileUpdateData(errorData);
    }
  };

  // // Handle note creation with optimistic update
  // const noteCreated = async (newNote) => {
  //   setNotes((prevNotes) => [newNote, ...prevNotes]);
  //   setNotesState("View");

  //   try {
  //     await createNoteAPI(newNote); // Replace with actual API call
  //     toast.success("Note added successfully");
  //   } catch (error) {
  //     setNotes((prevNotes) =>
  //       prevNotes.filter((note) => note.id !== newNote.id)
  //     );
  //     toast.error("Failed to add note");
  //   }
  // };

  const noteCreated = async () => {
    //get Notes
    const notes = await getResidentNotes(ResID);
    if (notes.data?.length > 0) setNotes(notes.data);
    //   if(notes.data?.length >0) setNotes([])
    else setNotes([]);
    setNotesState("View");
  };

  const setFragmentState = (name, state) => {
    let tempFrags = [...fragments];
    tempFrags.forEach((frag, i) => {
      if (frag.name === name) {
        tempFrags[i].state = state;
      }
    });
    setFragments(tempFrags);
  };

  const restoreResidentProfile = async () => {
    setCounter((count) => (count += 1));
    setProfileEditView(false);
  };

  const fragmentCreated = async (name) => {
    let fragmentsArray = [...fragments];
    for (let i = 0; i < fragmentsArray.length; i++) {
      const fragment = fragmentsArray[i];
      if (name === fragment.name) {
        let result = await getResidentFragment(fragment.name, ResID);
        fragmentsArray[i].items = result.data;
        setFragments(fragmentsArray);
      }
    }
    setFragmentState(name, "View");
  };

  const setFragmentToUpdated = (name, data) => {
    setFragmentToBeUpdated((prev) => ({ ...prev, [name]: data }));
    setFragmentState(name, "Manage");
  };

  const handleDelete = async () => {
    try {
      const res = await deleteResident(ResID);
      if (res.data?.message) {
        toast.success(res.data.message);
        setShowModal(false);
        history.push("/dashboard");
      }
    } catch (error) {
      console.error("Failed to delete resident:", error);
      toast.error("Failed to delete resident");
    }
  };

  const dateShower = (date) => {
    if (date && !(date instanceof Date)) {
      let dateString = date?.slice(0, 10);
      dateString = dateString.split("-");
      return dateString[1] + "-" + dateString[2] + "-" + dateString[0];
    }
  };

  useEffect(() => {
    if (!admissionHistory) return;
    let admissionData = admissionHistory.map((admission) => ({
      _id: admission.AdmissionID,
      out: admission.DateOut,
      in: admission.ProgramInDate || admission.GuestInDate,
    }));
    admissionData.sort((a, b) => new Date(b.in) - new Date(a.in));
    let dropdownOptions = [
      { _id: "all", name: "All Admissions", value: "all" },
    ];
    if (admission)
      dropdownOptions.push({
        _id: "current",
        name: "Current Admission",
        value: admission.AdmissionID,
      });

    admissionData.forEach((add, i) => {
      if (add?._id !== admission?.AdmissionID) {
        let string = `Admission ${admissionData.length - i} :  ${dateShower(
          add.in
        )} - ${dateShower(add.out)}`;
        dropdownOptions.push({
          _id: add._id,
          name: add.out ? string : "Current Admission",
          value: add._id,
        });
      }
    });
    setFragmentFilterDropdownOptions(dropdownOptions);
  }, [admissionHistory]);

  useEffect(() => {
    if (admission) {
      let phaseData = admission.PhaseData;
      if (!phaseData) {
        phaseData = [
          {
            phase: admission.RecentPhase,
            inDate: admission.GuestInDate,
          },
        ];
      } else {
        phaseData = JSON.parse(phaseData);
        phaseData = phaseData.map((item, i) => {
          return {
            id: i,
            inDate: item?.inDate,
            outDate: item?.outDate,
            phase: item?.phase,
          };
        });
      }
      setPhaseInfo(phaseData);
    }
  }, [admission]);

  const updatePhase = async (date, index) => {
    if (!date) {
      toast.error("Should be a valid date");
      return;
    }
    // Parse the first date into numerical components
    const [fYear, fMonth, fDay] = date.split("-").map(Number);

    // Create a Date object for the current date
    const currentDate = new Date();

    // Extract the UTC-based year, month, and day from the current date
    const cYear = currentDate.getUTCFullYear();
    const cMonth = currentDate.getUTCMonth() + 1; // Months are zero-based in JS
    const cDay = currentDate.getUTCDate();

    // Compare the two dates numerically (year, then month, then day)
    let isFirstDateGreater = false;
    if (fYear > cYear) {
      isFirstDateGreater = true;
    } else if (fYear === cYear) {
      if (fMonth > cMonth) {
        isFirstDateGreater = true;
      } else if (fMonth === cMonth) {
        if (fDay > cDay) {
          isFirstDateGreater = true;
        }
      }
    }
    console.log(fDay, cDay);

    if (isFirstDateGreater) {
      toast.error("End Date cannot be a future date");
      return;
    }

    let tempPhaseInfo = [...phaseInfo];
    tempPhaseInfo[index].outDate = date;
    if (tempPhaseInfo[index + 1]) {
      tempPhaseInfo[index + 1].inDate = date;
    }
    const data1 = {
      ResID: ResID,
      phaseData: tempPhaseInfo,
    };

    try {
      await updateResidentPhase(data1);
      phaseChanged();
      toast.success("Phase transition date Saved!");
    } catch (err) {
      console.error("Error updating phase:", err);
    }
  };

  const deletePhase = async (id) => {
    function removePhaseAndAdjustDates(phaseInfo, idToRemove) {
      // Find the index of the phase to remove
      const removeIndex = phaseInfo.findIndex(
        (phase) => phase.id === idToRemove
      );

      // If not found, just return the original array
      if (removeIndex === -1) {
        return phaseInfo;
      }

      // Extract the phase being removed
      const removedPhase = phaseInfo[removeIndex];

      // Create a copy of the array so we don't mutate the original
      const updatedPhaseInfo = [...phaseInfo];

      // Remove the identified phase
      updatedPhaseInfo.splice(removeIndex, 1);

      // If there is a previous phase, update its outDate to the removed phase's outDate
      if (removeIndex - 1 >= 0) {
        updatedPhaseInfo[removeIndex - 1].outDate = removedPhase.outDate;
      }

      // If there is a next phase (now at the same removeIndex after removal), update its inDate
      if (removeIndex < updatedPhaseInfo.length) {
        updatedPhaseInfo[removeIndex].inDate = removedPhase.outDate;
      }

      return updatedPhaseInfo;
    }
    console.log(id);
    if (id === 0) {
      toast.error(
        "Cannot delete phase 0 since it is tied to guest phase. Correct the guest in date in the admission to modify"
      );
      return;
    }
    const data1 = {
      ResID: ResID,
      phaseData: removePhaseAndAdjustDates(phaseInfo, id),
    };
    console.log(data1);
    try {
      await updateResidentPhase(data1);
      phaseChanged();
      toast.success("Phase Successfully Deleted");
    } catch (err) {
      console.error("Error deleting phase:", err);
    }
  };

  const phaseChanged = async () => {
    try {
      const { data: queriedResident } = await getResidentDetails(ResID);
      const { resident: updatedResident, admission: updatedAdmission } =
        queriedResident;
      setResident(updatedResident);
      setAdmission(updatedAdmission);
      setPhaseState("View");
    } catch (error) {
      console.error("Error updating phase:", error);
    }
  };

  const updateResImage = async ({ url, value, error }) => {
    if (error) return toast.error("Failed to update Image");

    try {
      let { data: updatedImageResident } = await updateResidentImage({
        key: value,
        ResID,
      });
      toast.success("Updated Image");
      return setResident(updatedImageResident);
    } catch (error) {
      if (!value) await awsService.deleteObject(value);
      return toast.error("Failed to update Image");
    }
  };

  return (
    <div>
      <ResTopBar
        user={user}
        resident={resident}
        admissions={admissionHistory}
        infractions={listInfractions}
      />
      <div className="dashboard-main-Scrollable pt-[150px]">
        <div className="pt-[45px] px-2">
          <div className="grid grid-cols-[55%,45%]">
            {/* {resident && ( */}
            <div className="">
              <div className="residentView-sectionBox">
                <div className="residentView-sectionBox-header pb-2">
                  <h3 className="primary font-medium text-[1.65rem]">
                    Identity
                  </h3>
                  <div className="">
                    {resident?.IsActive ? (
                      <a
                        href={`/dashboard/exit/${resident?.ResID}`}
                        className="px-4 ms-4 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600 no-underline "
                      >
                        Discharge
                      </a>
                    ) : (
                      resident &&
                      !resident?.IsActive &&
                      level3Access(user) && (
                        <div>
                          <Link
                            to={`/dashboard/create-admission/${resident.ResID}`}
                            className="nav-item"
                          >
                            <button className="b">Create Admission</button>
                          </Link>
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="flex flex-row gap-3">
                  <AWSImagePickerEdit
                    label={"Update Image"}
                    showLabel={false}
                    name={"resImage"}
                    value={resident?.ResPictureKey}
                    onChange={updateResImage}
                    url={resident?.ResPictureUrl}
                    buttonText={"+ Change picture"}
                  />
                  <div>
                    {!profileEditView ? (
                      <>
                        <Form
                          data={profileUpdateData}
                          onChange={() => {}}
                          submit={() => {
                            return setProfileEditView(true);
                          }}
                          isResidentUpdate={true}
                          disabled={true}
                          readOnly={true}
                        />
                        {level2Access(user) && (
                          <div className="flex justify-end">
                            <button
                              className="b"
                              onClick={() => setProfileEditView(true)}
                            >
                              Edit
                            </button>
                          </div>
                        )}
                      </>
                    ) : (
                      <Form
                        data={profileUpdateData}
                        onChange={handleProfileFieldUpdation}
                        submit={handleProfileUpdateSubmit}
                        isResidentUpdate={true}
                        buttonLabel={"Save"}
                        secondaryAction={() => {
                          restoreResidentProfile();
                          setProfileEditView(false);
                        }}
                        secondaryActionLabel="Cancel"
                        readOnly={!level2Access(user)}
                      />
                    )}

                    {ProfileUpdatemessage && (
                      <div className="text-center">{ProfileUpdatemessage}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-row">
                {admissionHistory && (
                  <>
                    <div className="residentView-sectionBox">
                      <div className="residentView-sectionBox-header">
                        <h3 className="primary font-medium text-[1.65rem]">
                          Stay History
                        </h3>
                      </div>
                      <AdmissionRecords data={admissionHistory} />
                    </div>
                  </>
                )}
                {phaseInfo && (
                  <div className="residentView-sectionBox">
                    <div className="residentView-sectionBox-header">
                      <div>
                        <h3 className="primary font-medium text-[1.65rem]">
                          Phase History
                        </h3>
                      </div>
                      {(level4Access(user) || user?.isCaseCoordinator) &&
                        PhaseState === "View" &&
                        resident.IsActive && (
                          <div className="">
                            <button
                              className="b text-sm"
                              onClick={() => setPhaseState("Change")}
                            >
                              Change Phase
                            </button>
                          </div>
                        )}
                    </div>
                    {PhaseState === "View" ? (
                      <div className="PhaseManagement-Container">
                        <PhaseList
                          dateOut={admission.DateOut}
                          isActive={resident.IsActive}
                          data={phaseInfo}
                          setEditPhaseIndex={setEditPhaseIndex}
                          setPhaseState={setPhaseState}
                          modifyPhase={updatePhase}
                          deletePhase={deletePhase}
                        />
                      </div>
                    ) : PhaseState === "Edit" ? (
                      <PhaseUpdate
                        setPhaseState={setPhaseState}
                        editPhaseIndex={editPhaseIndex}
                        modifyPhase={updatePhase}
                        deletePhase={deletePhase}
                        onChange={phaseChanged}
                        ResId={ResID}
                        phaseData={phaseInfo}
                      />
                    ) : (
                      <PhaseChange
                        setPhaseState={setPhaseState}
                        onChange={phaseChanged}
                        ResId={ResID}
                        phaseData={phaseInfo}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
            {/* )} */}
            <div className="residentView-sectionBox">
              {notes && (
                <div>
                  <div className="residentView-sectionBox-header">
                    <h3 className="primary font-medium text-[1.65rem]">
                      Case Notes
                    </h3>
                    {NotesState === "View" ? (
                      <button
                        className="b"
                        onClick={() => setNotesState("Create")}
                      >
                        Add Note
                      </button>
                    ) : (
                      <button
                        className="b blackButton"
                        onClick={() => setNotesState("View")}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                  {NotesState === "View" ? (
                    <NotesList data={notes} noteSubjects={noteSubjects} />
                  ) : (
                    <CreateNote
                      onCreate={noteCreated}
                      ResId={ResID}
                      noteSubjects={noteSubjects}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          {resident?.IsActive &&
            resident?.RecentAdmissionID &&
            level1Access(user) && (
              <div className="residentView-sectionBox">
                <div className="residentView-sectionBox-header">
                  <h3 className="primary font-medium text-[1.65rem]">
                    Infractions
                  </h3>
                  <h3 className="primary font-medium text-[1.65rem]">
                    Total Points:{" "}
                    {listInfractions
                      ?.filter((i) => checkDate(i.Date))
                      .reduce((sum, infraction) => sum + infraction?.Points, 0)}
                  </h3>
                </div>
                <DisciplinePoints
                  ResID={resident?.ResID}
                  user={user}
                  AdmissionID={resident?.RecentAdmissionID}
                  listInfractions={listInfractions}
                  setListInfractions={setListInfractions}
                />
              </div>
            )}
          {resident?.ResID && financeAccess(user) && (
            <div className="residentView-sectionBox">
              <ServiceAgreementLetters
                ResID={resident?.ResID}
                level4Access={level4Access}
              />
            </div>
          )}
          {resident && (level2Access(user) || admission) && (
            <>
              <div className="residentView-SubTitle px-2">
                <div className="residentView-Header row">
                  <h2 className="primary ps-1 font-medium text-[1.65rem]">
                    Resident Fragments
                  </h2>
                  {level2Access(user) && (
                    <div className="checkin-Container-Section backgroundLight marginBottom roundedCorners paddingRight01 paddingLeft01 paddingVertical  alignCenter">
                      <h5>Filter Fragments by Admission</h5>
                      <div className="checkin-Contianer-Dropdown">
                        <Select
                          options={FragmentFilterDropdownOptions}
                          onChange={(value) =>
                            setFragmentFilter(value.currentTarget.value)
                          }
                          value={FragmentFilter}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2">
                {fragments.length > 0 ? (
                  fragments.map((fragment) => (
                    <div
                      className="residentView-sectionBox"
                      key={fragment.name}
                    >
                      <div className="residentView-sectionBox-header">
                        <h3 className="primary font-medium text-[1.65rem]">
                          {fragment.title}
                        </h3>
                        {fragment.state === "View" ? (
                          user.isCaseCoordinator ||
                          user.isAdmin ||
                          user.isCenterCoordinator ? (
                            <button
                              className="b secondayButton"
                              onClick={() =>
                                setFragmentState(fragment.name, "Create")
                              }
                            >
                              Add
                            </button>
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                      {fragment.state === "View" ? (
                        <FragmentList
                          data={fragment.items}
                          filter={FragmentFilter}
                          title={fragment.titleName}
                          familyContact={fragments[0].items}
                          list={fragment.list}
                          setFragmentState={setFragmentState}
                          onManage={(data) =>
                            setFragmentToUpdated(fragment.name, data)
                          }
                        />
                      ) : fragment.state === "Manage" ? (
                        <UpdateFragment
                          onUpdate={fragmentCreated}
                          name={fragment.name}
                          setFragmentState={setFragmentState}
                          contactFragment={fragments[0]}
                          data={FragmentToBeUpdated[fragment.name]}
                        />
                      ) : (
                        <CreateFragment
                          onCreate={fragmentCreated}
                          ResId={ResID}
                          name={fragment.name}
                          setFragmentState={setFragmentState}
                          contactFragment={fragments[0]}
                          AdmissionID={
                            admission ? admission.AdmissionID : undefined
                          }
                        />
                      )}
                    </div>
                  ))
                ) : (
                  <div className="residentView-sectionBox">Loading...</div>
                )}
              </div>
            </>
          )}

          {resident && level5Access(user) && (
            <>
              <div className="flex items-center justify-end mt-2 pe-3 mb-3">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
                  onClick={() => setShowModal(true)}
                >
                  Delete Resident
                </button>

                {showModal && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded shadow-lg p-6 w-96">
                      <h3 className="text-lg font-semibold">Are you sure...</h3>
                      <p className="mt-2 text-gray-600">
                        Do you really want to delete this Resident?
                      </p>
                      <div className="mt-4 flex justify-end">
                        <button
                          className="px-4 py-2 mr-2 text-white bg-green-500 rounded hover:bg-green-600"
                          onClick={handleDelete}
                        >
                          Yes
                        </button>
                        <button
                          className="px-4 py-2 text-white bg-gray-500 rounded hover:bg-gray-600"
                          onClick={() => setShowModal(false)}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateResident;
