/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { updateResidentPhase } from "../../../services/residentService";
import { getPhaseChangeObject } from "../../../utils/phaseChangeObject";

import Form from "../simpleForm";
import { toast } from "react-toastify";

const PhaseUpdate = ({
  onChange,
  ResId,
  phaseData,
  editPhaseIndex,
  setPhaseState,
  ...props
}) => {
  const [phaseChangeObject, setPhaseChangeObject] = useState(
    getPhaseChangeObject()
  );
  const [message, setMessage] = useState("");
  const [selectedPhase, setSelectedPhase] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  useEffect(() => {
    const asyncFunc = async () => {
      if (phaseData.length > 0) {
        const currentPhase = phaseData[editPhaseIndex];
        setSelectedPhase(currentPhase?.phase);
        setEndDate(currentPhase?.outDate?.slice(0, 10));
        setStartDate(currentPhase?.inDate?.slice(0, 10));
        let tempObject = [...phaseChangeObject];
        tempObject[0][1].value = new Date();
        setPhaseChangeObject(tempObject);
      }
    };
    asyncFunc();
  }, []);

  const handleChange = (name, item) => {
    const tempObject = [...phaseChangeObject];
    tempObject[parseInt(name[1], 10)][parseInt(name[2], 10)] = item;
    setPhaseChangeObject(tempObject);
  };
  const handleChangePhase = () => {
    console.log(startDate, endDate);
    if (!endDate) {
      toast.error("Should be a valid date");
      return;
    }
    if (new Date(startDate) > new Date(endDate)) {
      toast.error("End Date cannot be prior to Start Date");
      return;
    }

    props.modifyPhase(endDate, editPhaseIndex);
    setPhaseState("View");
  };
  const handleDeletePhase = () => {
    props.deletePhase(editPhaseIndex);
    setPhaseState("View");
  };
  //   const handleCancel = () => {
  //     onCancel();
  //   };
  const handleSubmit = async ({ validation, errorData }) => {
    if (validation) {
      phaseData[phaseData.length - 1].outDate = phaseChangeObject[0][1].value;
      phaseData.push({
        phase: phaseChangeObject[0][0].value,
        inDate: phaseChangeObject[0][1].value,
      });
      const data1 = {
        ResID: ResId,
        phaseData,
      };

      try {
        let { data } = await updateResidentPhase(data1);
        if (data) onChange();
        else setMessage("Failed to Change Phase");
      } catch (error) {
        //@ts-ignore
        setMessage("Failed to Change Phase");
      }
    } else setPhaseChangeObject(errorData);
  };

  return (
    <div>
      <div className="flex flex-col items-start space-y-4 p-4 px-0">
        <h2 className="text-2xl font-semibold text-teal-600">
          Phase {selectedPhase}
        </h2>
        <div className="flex flex-row justify-between  mt-2 items-center gap-4">
          {/* <div className="w-full">
            <label
              htmlFor="phaseSelect"
              className="block font-medium text-teal-700 mb-1"
            >
              Phase
            </label>
            <select
              id="phaseSelect"
              value={selectedPhase}
              onChange={(e) => setSelectedPhase(e.target.value)}
              className="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 focus:outline-none focus:ring-1 focus:ring-teal-500 focus:border-teal-500"
            >
              {[0, 1, 2, 3, 4].map((phase) => (
                <option key={phase} value={phase}>
                  {phase}
                </option>
              ))}
            </select>
          </div> */}
          <div className="w-full">
            <label
              htmlFor="startDate"
              className="block font-medium text-teal-700 mb-1"
            >
              Start Date
            </label>
            <input
              id="startDate"
              type="date"
              value={startDate}
              disabled
              //   onChange={(e) => setStartDate(e.target.value)}
              className="block w-full rounded-md border border-gray-300 py-2 px-3 focus:outline-none focus:ring-1 focus:ring-teal-500 focus:border-teal-500"
            />
          </div>

          <div className="w-full">
            <label
              htmlFor="endDate"
              className="block font-medium text-teal-700 mb-1"
            >
              End Date
            </label>
            <input
              id="endDate"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="block w-full rounded-md border border-gray-300 py-2 px-3 focus:outline-none focus:ring-1 focus:ring-teal-500 focus:border-teal-500"
            />
          </div>
        </div>

        <div className="flex justify-between w-full space-x-2 pt-4">
          <button
            type="button"
            onClick={handleDeletePhase}
            className="inline-flex items-center rounded-md border border-transparent bg-red-500 px-4 py-2.5 font-medium text-white "
          >
            Delete
          </button>

          <button
            type="button"
            onClick={handleChangePhase}
            className="inline-flex items-center rounded-md border border-transparent bg-teal-600 px-4 py-2.5 font-medium text-white "
          >
            Update
          </button>

          <button
            type="button"
            onClick={() => setPhaseState("View")}
            className="inline-flex items-center rounded-md border border-transparent bg-gray-700 px-4 py-2.5 font-medium text-white"
          >
            Cancel
          </button>
        </div>
      </div>
      {message && <div className="updateResident-footer">{message}</div>}
    </div>
  );
};

export default PhaseUpdate;
